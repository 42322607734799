@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  src: local("Ubuntu"), url("./fonts/Ubuntu-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Ubuntu-Medium"),
    url("./fonts/Ubuntu-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-Light";
  font-style: normal;
  font-weight: 300;
  src: local("Ubuntu-Light"), url("./fonts/Ubuntu-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Ubuntu-Regular"),
    url("./fonts/Ubuntu-Regular.ttf") format("truetype");
}

.nav-tabs {
  border-bottom: 1px solid #ffffff !important;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent !important;
}

.nav-link.active {
  color: #268cd1 !important;
  /* background-color: #f1f4f9 !important; */
  /* border-bottom: 1px solid #f1f4f9 !important; */
  border: none;
}

@media screen and (max-width: 1000px) {
  .approve-string {
    display: none;
  }

  .reject-string {
    display: none;
  }

  .unlock-string {
    display: none;
  }

  .admin-string {
    display: none;
  }
}

// .upload-data-body > .table th,.table td {
//   padding: 0.3rem;
//   width: 20% !important;
// }

// .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
//   color: #495057 !important;
//   /* background-color: #f1f4f9 !important; */
//   border-bottom: 1px solid #f1f4f9 !important;
// }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn {
  box-shadow: 0 0 3px 0px #8a8080;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-family: Ubuntu;
}

.MuiDrawer-paper {
  overflow-y: hidden !important;
}

.MuiButtonBase-root {
  outline: none !important;
}

.login-img {
  padding-right: 0px !important;
}
